<template>
  <div class="page-info" v-if="langInfo">
    <div class="user-info">
      <div class="info-head">
        <img class="head" src="../../assets/images/sun.png" />
      </div>
      <!-- 现在注册 登录 -->
      <div class="info-texts">
        <div class="texts-group">
          <div
            class="texts-name"
            @click="$router.replace('/index?register=true')"
          >
            {{ langInfo.newLangInfo.guidePage.register }}
          </div>
          <div class="texts-split"></div>
          <div class="texts-name" @click="$router.replace('/index?login=true')">
            {{ langInfo.newLangInfo.guidePage.signin }}
          </div>
        </div>
      </div>
    </div>
    <div class="coupon-info">
      <!-- 购物 图标 -->
      <div class="coupon-view">
        <div class="texts-desc">
          {{ langInfo.newLangInfo.guidePage.title }}
        </div>
        <!-- <div class="coupon-item" style="margin-top: 24px;">
					<div class="item-app">
						<img class="coupon" src="../../assets/images/laz.png">
						<img class="coupon" src="../../assets/images/ss.png">
						<img class="coupon" src="../../assets/images/tk.png">
					</div>
					<div class="item-btn" @click="handTips">
						{{ langInfo.newLangInfo.guidePage.submit }}
						<img class="right-black" src="../../assets/images/right-black.png">
					</div>
				</div> -->
      </div>
      <div class="fixed-footer">
        <div
          class="footer-app"
          v-if="
            showDataInfo && showDataInfo.urlList && showDataInfo.urlList.length
          "
        >
          <template v-for="item in showDataInfo.urlList">
            <img
              class="coupon"
              v-if="item.name == 'Shopee'"
              :src="require('../../assets/shopee.png')"
              alt="Shopee Simplus"
              @click="handOpen(item.url)"
            />
            <img
              class="coupon"
              v-if="item.name == 'Lazada'"
              :src="require('../../assets/lazada.png')"
              alt="TikTok Simplus"
              @click="handOpen(item.url)"
            />
            <img
              class="coupon"
              v-if="item.name == 'TikTok'"
              :src="require('../../assets/tt.png')"
              alt="TikTok Simplus"
              @click="handOpen(item.url)"
            />
            <img
              class="coupon"
              v-if="item.name == 'Tokopedia'"
              :src="require('../../assets/Tokopedia.png')"
              alt="Tokopedia"
              @click="handOpen(item.url)"
            />
          </template>
          <!--泰国的 lin  替换 src-->
          <!-- <img class="coupon" v-if="formData.Nation==='TH'" :src="require('../../assets/Tokopedia.png')"
						alt="Tokopedia" @click="handOpen('https://lin.ee/S1d5PiN')" /> -->
        </div>
        <div class="footer-copy">
          <img class="icon" src="../../assets/images/icons/click.jpg" /> Click
          to Shop
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { List } from "../../util/en_config_new";
import { langData } from "../../util/lang_config_new";
import { Popup, Toast } from "vant";
export default {
  components: {
    [Popup.name]: Popup,
  },
  data() {
    return {
      showSex: false,
      formData: {},
      langInfo: null,
      showDataInfo: null, //包含手机号校验正则
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (localStorage.getItem("NationName")) {
        //this.formData = JSON.parse(localStorage.getItem('LoginInfo'));
        this.formData.Nation = localStorage.getItem("NationName");
        this.langInfo = this.setLang();
      } else {
        this.$router.replace("/index");
      }
    },
    handOpen(url) {
      location.href = url;
    },
    handTips() {
      Toast("Please Click Bottom");
    },
    setLang() {
      let Info = langData.filter(
        (res) => res.Nation == this.formData.Nation
      )[0];
      this.showDataInfo = List.filter(
        (res) => res.Nation == this.formData.Nation
      )[0];
      return Info == undefined ? langData[0] : Info;
    },
  },
};
</script>

<style lang="less">
.page-info {
  width: 100vw;
  height: 100vh;
  background: #4e5e9a;
  position: relative;

  .user-info {
    padding: 48px 24px 24px;
    display: flex;
    align-items: center;

    .info-head {
      position: relative;
      width: 75px;
      height: 75px;

      .head {
        width: 75px;
        height: 75px;
        border-radius: 50%;
      }
    }

    .info-texts {
      padding-left: 16px;

      .texts-group {
        display: flex;
        align-items: center;

        .texts-name {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          text-decoration: underline;
        }

        .texts-split {
          border-left: 1px solid #ffffff;
          height: 14px;
          margin: 0px 12px;
        }
      }
    }
  }

  .coupon-info {
    position: absolute;
    bottom: 0px;
    width: 100vw;
    height: 75vh;
    border-radius: 24px 24px 0px 0px;
    background: #ffffff;

    .coupon-view {
      padding: 42px 12px;
      position: relative;
      //height: calc(70vh - 120px);

      .texts-desc {
        padding: 12px 8px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #000000;
        text-align: center;
      }

      .coupon-item {
        height: 100px;
        background: #f4f5fa;
        border-radius: 4px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .item-app {
          display: flex;
          justify-content: center;

          .coupon {
            width: 48px;
            height: 48px;
            margin-right: 12px;
          }

          .coupon:last-child {
            margin-right: 0px;
          }
        }

        .item-btn {
          padding: 2px 12px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          text-align: center;
          border-radius: 18px;
          border: 1px solid #333333;
          display: flex;
          justify-content: center;
          align-items: center;

          .right-black {
            width: 5px;
            height: 10px;
            padding-left: 8px;
          }
        }
      }
    }

    .fixed-footer {
      width: 100vw;
      // position: absolute;
      // bottom: 88px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .footer-app {
        display: flex;
        justify-content: center;

        .coupon {
          width: 48px;
          height: 48px;
          margin-right: 12px;
        }

        .coupon:last-child {
          margin-right: 0px;
        }
      }

      .footer-copy {
        margin-top: 12px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4c4c4c;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          width: 16px;
          height: 14px;
          margin-right: 4px;
        }
      }
    }
  }
}
</style>
